.verify {
  input {
    background: #F5F5F8;
    width: 42px;
    height: 42px;
    border-radius: 17px;
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin: 3px;

    &.error {
      background: rgba(252, 139, 138, 0.1);
      color: #FC8B8A;
    }
  }
}
@root-entry-name: default;@font-family: Gilroy;@primary-color: #7264D6;