




.ymap_block {
  position: relative;
  box-sizing: content-box;
  width: 100%;
  height: 400px;

  .zone {
    position: relative;
    box-sizing: content-box;
    width: 100%;
    height: 400px;
    overflow-y: hidden;
  }
}

.integration {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 9px;
  transition: all 0.5s;

  p {
    margin: 0;
  }
  .name {
    font-size: 18px;
    font-weight: 800;
    line-height: 1.2;
  }

  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 10px;
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    transition: all 0.5s;
  }
}
.default-pin {
  width: 26px;
  height: 26px;
  transform: translate(-50%, -50%);

  svg {
    width: 26px;
    height: 26px;

    path {
      color: @primary-color;
    }
  }
}
.App-map {
  height: 400px;
  width: 100%;
}
.pushes_templates {
  margin: 30px 0;
  background: #f1f1f1;
  padding: 30px;
  border-radius: 30px;

  p.title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.6;
    letter-spacing: 0.2px;
    color: #3F3D56;
  }

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: 0.2px;
    color: #3F3D56;
  }

  .element {
    background: white;
    border-radius: 15px;
    padding: 20px;
  }
}

.icon_select {
  svg {
    path {
      fill: #9E9E9E;
    }
  }

  div {
    width: 40px;
    height: 40px;
    position: relative;

    display: inline-block;
    margin-right: 15px;
    margin-bottom: 15px;
    border-radius: 500px;
    border: 1px dashed #e1e1e1;
    padding: 10px;
    opacity: 0.6;
    transition: opacity 0.5s;

    &:hover {
      opacity: 1;
      cursor: pointer;
      transition: opacity 0.5s;
    }

    &.active {
      opacity: 1;
      border-color: @primary-color;
      //svg {
      //  path {
      //    opacity: 1;
      //    // fill: @primary-color;
      //  }
      //}
    }

    svg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      max-width: 80%;
      max-height: 80%;
    }
  }
}

label.label_default {
  text-transform: uppercase;
  font-size: 12px;
  margin-top: 20px;
}

.selected_elements {
  transition: all 0.5s;
  width: 100%;
  max-width: 1200px;
  position: fixed;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: 20px auto 0;
  padding: 15px 30px;

  .element {
    background-color: @primary-color;
    color: white;
    border-radius: 15px;
    padding: 15px 30px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  }
}

.editor_textarea {
  font-size: 16px;
}
.editor_link {
  font-size: 18px;
  text-decoration: underline;
  font-weight: 600;
  color: #0b7ad1;
}
.editor_link_link {
  font-size: 14px;
}

.app {
  background-color: #f5f5f9;
  padding: 15px;
  border-radius: 15px;

  p.name {
    font-size: 16px;
    font-weight: 600;
  }

  div.tags {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}

.select_account {
  font-size: 22px;
  font-weight: bold;
  background-color: transparent;
  border: none;
  opacity: 0.7;
  transition: opacity 0.3s;

  span {
    font-size: 11px;
    display: block;
    opacity: 0.8;
  }

  &:hover {
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s;
  }
}

.dashboard-alert {
  background: #FFFFFF;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  opacity: 1;
  padding: 15px 20px;
  position: relative;

  &.dashboard-alert-info {
    background: #1976D2;
    color: white;
  }

  &.dashboard-alert-success {
    background: #2E7D32;
    color: white;
  }
}

.opacity_plan {
  opacity: 0.6;
}

.order_history {
  font-size: 13px;
}
@root-entry-name: default;@font-family: Gilroy;@primary-color: #7264D6;