//@import 'antd/lib/input/style/mixin';

.auth {
  width: 100%;
  text-align: center;
  height: calc(100vh - 90px);

  .fixed {
    width: 100%;
    max-width: 400px;
    margin: 0 auto !important;
  }

  .verify-block {
    text-align: center;
  }

  .forgot-description {
    text-align: center;
    padding-top: 6px;
    margin-bottom: 20px;

    p {
      width: 100%;
      max-width: 414px;
      margin: 0 auto;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: #9D9EA9;
    }
  }

  .verify-description {
    text-align: center;
    padding-top: 6px;
    margin-bottom: 20px;

    p {
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: #9D9EA9;
    }
  }

  .verify-button {
    text-align: center;

    button {
      span {
        margin-left: 15px;
      }

      svg path {
        stroke: @primary-color;
      }
    }
  }

  h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 38px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.2px;
    color: #3F3D56;
    margin-bottom: 40px;

    &.forgot {
      margin-bottom: 0;
    }

    &.verification {
      margin-bottom: 20px;
    }
  }

  .errors {

    &.short {
      text-align: center;

      p {
        margin-left: 0;
      }
    }

    svg {
      margin-left: 20px;
    }

    p {
      margin-top: -2px;
      margin-left: 15px;
      color: #FC8B8A;
    }
  }

  .ant-input-affix-wrapper, .form-control  {
    width: 100%;
    // background: #F5F5F8;
    opacity: 0.7;
    border-radius: 3px;
    border: 1.5px solid rgba(#3F3D56, 0.3);
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.2px;
    padding: 7px 20px;
    transition: all 0.5s;
    outline: none;

    &.error {
      background: rgba(252, 139, 138, 0.1);

      &.ant-input-affix-wrapper-focused {
        svg path {
          stroke: #FC8B8A;
        }
      }

      svg path {
        stroke: #FC8B8A;
      }
    }

    &.ant-input-affix-wrapper-focused {
      svg path {
        stroke: #7098E4;
        opacity: 1;
        transition: all 0.5s;
      }
    }

    input.ant-input {
      background-color: transparent;
      padding-left: 14px;
    }
  }


  .form-control {
    padding-left: 57px;

    //&:focus {
    //  .active();
    //}
  }

  ._forgot {
    text-align: right;
  }
  .buttons {
    margin-top: 30px;
    text-align: center;

    .right {
      text-align: right;
    }

    .left {
      text-align: left;
    }

    .button {
      display: inline-block;
      width: 171.3px;
      height: 50px;
      border-radius: 17px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 50px;
      text-align: center;
      letter-spacing: 0.2px;
      color: #FFFFFF;
      padding: 0;

      &.enter {
        opacity: 0.7;
      }

      &:disabled {
        background: #D4D3E3;
        opacity: 0.6;
      }

      &.register {
        background: #F878C2;
        border-color: #F878C2;
      }
    }
  }

  a.doc {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.2px;
    opacity: 0.9;
    color: #9D9EA9;
  }
}
@root-entry-name: default;@font-family: Gilroy;@primary-color: #7264D6;