.spin {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  background-color: rgba(255, 255, 255, 0.1);

  div {
    width: 24px;
    height: 27px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
}
@root-entry-name: default;@font-family: Gilroy;@primary-color: #7264D6;