.loader_page {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
  transition: 1s all;

  .loader_page_block {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 33px;
    height: 33px;
    transition: 1s all;
  }
}

.loader_local {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  border-radius: inherit;
  transition: 1s all;

  .loader_page_block {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 33px;
    height: 33px;
    transition: 1s all;
  }
}
@root-entry-name: default;@font-family: Gilroy;@primary-color: #7264D6;