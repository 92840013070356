@import '../../assets/styles/var';

header {
  background-color: @primary-color;
  margin-bottom: 30px;

  @media (@xs) {
    padding: 0 15px;
    margin-bottom: 0;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1001;
  }

  .lang-text {
    text-transform: capitalize;
  }

  .mobile {
    background: transparent;
    color: white;
    border: none;
  }

  .logo {
    padding: 15px;

    img {
      height: 30px;
    }
  }

  .user {
    text-align: right;

    p.name {
      color: white;
      font-weight: 500;
      font-size: 14px;
      margin: 0;
    }
  }

  .search {
    width: 100%;
    border: none;
    border-radius: 7px;
    padding: 8px 12px;
    background-color: rgba(255, 255, 255, 0.2);

    &::placeholder {
      color: rgba(255, 255, 255, 0.6);
    }
  }
}

.mobile-nav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  background-color: #F5F5F8;
  overflow-y: auto;

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    color: black;
    z-index: 1002;
  }
}

.ant-table-wrapper {
  overflow-x: auto;
}
@root-entry-name: default;@font-family: Gilroy;@primary-color: #7264D6;