.dashboard {
  background: #FFFFFF;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.08);
  border-radius: 17px;
  opacity: 1;
  padding: 30px 30px 20px;
  position: relative;

  .right {
    text-align: right;
  }

  .device {
    width: 40px;
    height: 40px;
    position: relative;
    border-radius: 7px;
    border: 1px dashed #f1f1f1;

    &:hover {
      cursor: pointer;
    }

    &.disabled {
      opacity: 0.6;
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 20px;
    }
  }

  .item {
    font-size: 12px;
    border-bottom: 1px dashed #e1e1e1;
    margin-top: 15px;

    .price {
      text-align: right;
    }
  }

  &.dashboard-no-active {
    opacity: 0.5;
  }

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 130%;
    letter-spacing: 0.2px;
    color: #3F3D56;
  }
  p {
    font-style: normal;
    font-weight: 500;
    // font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.2px;
    color: #9D9EA9;

    &.num {
      font-size: 32px;
      font-weight: bold;
    }
  }

  .dashboard-link {
    padding: 10px 0;

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.2px;
      color: #3F3D56;
      margin: 0;
    }

    a {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 37px;
      letter-spacing: 0.2px;
      color: #3F3D56;
      transition: 0.5s all;

      &:hover {
        color: #7098E4;
        transition: 0.5s all;
      }
    }

    .right {
      text-align: right;

      button {
        background: transparent;
        border: none;
        opacity: 0.5;
        transition: opacity 0.5s;

        &:hover {
          cursor: pointer;
          opacity: 1;
          transition: opacity 0.5s;
        }
      }
    }
  }
}
@root-entry-name: default;@font-family: Gilroy;@primary-color: #7264D6;